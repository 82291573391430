import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="spirits-page">
      {/* <div className="top-banner" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/ure9ymqsporphmwvvitt)"}}>
        <h1>Catering</h1>
      </div> */}
      <div className="landing-page-wrapper">
        <div className="order-prompt">Available To Go & Delivery</div>
        {/* <div className="promo"><span className="promo-text">5% Loyalty Rewards</span></div> */}
      </div>

      <AutoMenu tagsToShow={['bar']} />

      {/* <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div> */}
    </div>
  )
}
