import React from 'react'
export default function ({data}) {
  const {
    selectedAmount,
    note,
    buyerName,
    recipientName,
    buyerEmail,
    recipientEmail
  } = data
  return(

    <svg viewBox="0 0 442 275">
    <defs>
      <path
        id="prefix__a"
        d="M23 263.5c-6.3 0-11.5-5.2-11.5-11.5V21c0-6.3 5.2-11.5 11.5-11.5h397c6.3 0 11.5 5.2 11.5 11.5v231c0 6.3-5.2 11.5-11.5 11.5H23z"
      />
    </defs>
    <clipPath id="prefix__b">
      <use xlinkHref="#prefix__a" overflow="visible" />
    </clipPath>
    <g clipPath="url(#prefix__b)">
      <image
        overflow="visible"
        width={1421}
        height={851}
        xlinkHref="https://afag.imgix.net/los-amigos/background-1.jpg?w=1000"
        transform="translate(5.098 5.944) scale(.3068)"
      />
    </g>
    <text
      transform="translate(334 58.372)"
      fontFamily="'AmericanTypewriter-Bold'"
      fontSize={27.338}
    >
      {'$' + selectedAmount}
    </text>
    <text
      transform="translate(271.149 248.298)"
      fontFamily="Helvetica Neue"
      fontSize={17.42}
    >
      {buyerName}
    </text>
    <text
      transform="translate(133.313 136.5)"
      fontFamily="Helvetica Neue"
      fontSize={16.732}
    >
      {recipientName + ','}
    </text>
    <path fill="none" d="M184 204h241.5v56H184z" />
    <text
      transform="translate(134.313 154.284)"
      fontFamily="Helvetica Neue"
      fontSize={12}
    >
      {note}
    </text>
    <image
      overflow="visible"
      width={609}
      height={247}
      xlinkHref="https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,h_400/vfbfxkg6xwojalgk7trr"
      transform="translate(32.202 39.867) scale(.3386)"
    />
  </svg>

  )
}
