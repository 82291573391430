import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Catering from './Catering'
import About from './About'
import Contact from './Contact'
// import Menu from './Menu'
import Kitchen from './Kitchen'
import Bakery from './Bakery'
import Wine from './Wine'
import Specials from './Specials'
import HappyHour from './HappyHour'
import Spirits from './Spirits'
import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
import './wine.scss'
import urls from './urls'
import PoweredBy from './PoweredBy'
import GiftCards from './GiftCards'
import {sections, sectionPagesDef} from './sections'
import ThemeToggle from './ThemeToggle'

export const customOrderAppPaths = ["/", "/menu", "/order"]

const urlMappings = urls.filter(({content, redirect}) => {
  return !!content || !!redirect
}).map(({origin, content, redirect}) => {
  const pageObj = { path: origin, unlisted: true }
  if (content) {
    pageObj.component = content
  }
  if (redirect) {
    console.log(redirect)
    pageObj.component = () => (<Redirect to={redirect}></Redirect>)
  }
  return pageObj
})

export const pages = [
  // {path: "/order", label: "Order"},
  // {path: "/menu", label: "menu" },
  {path: "/specials", label: "Specials", component: Specials},
  // {path: "/happy-hour", label: "Happy Hour", component: HappyHour},
  {path: "/the-bar", label: "The Bar", component: Spirits},
  {path: "/gift-card", label: "Gift Card", component: GiftCards},
  // {path: "/breakfast-lunch", label: "breakfast/lunch", component: Kitchen},
  // {path: "/bakery", label: "Bakery", component: Bakery},
  // {path: "/catering", label: "Catering", component: Catering},
  // {path: "/about-us", label: "About", component: About},
  // {path: "/just-the-facts", label: "Contact", component: Contact},
  // {path: "/gift-card", label: "Gift Card", component: GiftCards},
  // {path: "/delivery", label: "Delivery", component: Delivery},
  ...sectionPagesDef
].concat(urlMappings)

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">

        <ul>
          {pages.map(({path, label, external, hidden, unlisted}) => {
            if (hidden || unlisted) { return null}
            return (
            <li>
              {external ?
                <a className="page-link" href={path}>{label}</a> :
                <NavLink className="page-link" exact to={path}>{label}</NavLink>}
            </li>
          )})}
          <ThemeToggle></ThemeToggle>
        </ul>

    </nav>
  )}
}

const quickLinks = [
  { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]
//
// const sections = [
//   {
//     name: "Lunch Specials",
//     hero: "",
//     text: "Everyday 11am to 3pm"
//     link: "/specials"
//   }, {
//
//   }
// ]


export class Header extends PureComponent {
  render() {
    return(
      <div className="landing-page-wrapper">
        <div className="order-prompt">Place your pickup / delivery order here</div>
        <div className="promo"><span className="promo-text">5% Loyalty Rewards</span></div>
      </div>
    )

  }
}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isTuesday = [2].indexOf(day) !== -1

  const isTuesdayMenu =     tag.indexOf('taco-tuesday') !== -1 
  const isLunchMenu =     tag.indexOf('lunch-specials') !== -1 
  if (isTuesdayMenu) {
    if (isTuesday) {
      if ((hours>= 14) && (hours < 19)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  if (isLunchMenu) {
    if ((hours > 10) && (hours < 15)) {
      return true
    } else {
      return false
    }
  }


  return true
}
