
import * as Components from "./Losamigos"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "losamigos"
}

